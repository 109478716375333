@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap');
//@import "~video-react/styles/scss/video-react";

$azul-claro: #b3e3ff;

html {
  height: 100%;
}

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-color: #f5f5f5;*/
  background-color: #e5e5e5;
  /*background-color: white;*/
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.MuiTypography-root {
  font-family: "Open Sans", sans-serif;
}

.MuiOutlinedInput-root {
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:not(.MuiButton-root) {
  text-decoration: none;
  color: blue;
}

/*
.MuiListItemButton-root.Mui-selected {
  background-color: rgba(112, 128, 144, 0.589);
}
.MuiListItemButton-root.Mui-selected:hover {
  background-color: slategray;
}
*/
.MuiTableCell-head {
  font-weight: bold;
  text-align: center;
}

/*
.MuiTextField-root {
  margin-bottom: 5px;
}
*/

.textAlignCenter {
  text-align: center;
}

.textAlignRight {
  text-align: right;
}

.abaEtpTr {
  text-align: left;
  //text-transform: none !important;
  border-bottom: 1px solid lightgray;
  width: 100%;
}

.block-ui {
  position: fixed;
  background-color: black;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conteudo-item-notificacao {
  margin-bottom: 5px;
  font-size: smaller;
  text-align: justify;
  display: flex;
  border: 1px solid;
  border-radius: 4px;
  padding: 2px;
}

.conteudo-item-notificacao-rt {
  flex-direction: row-reverse;
  margin-right: 20px;
  background-color: whitesmoke;
  border-color: lightslategray;
}

.conteudo-item-notificacao-analista {
  flex-direction: row;
  margin-left: 20px;
  background-color: mintcream;
  border-color: seagreen;
}

.conteudo-item-notificacao-rt>div>.autor-item-notificacao {
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 5px;
  float: left;
}

.conteudo-item-notificacao-analista>div>.autor-item-notificacao {
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 5px;
  float: right;
}

.paragrafo {
  margin-bottom: 10px;
  text-indent: 25px;
}

.paragrafo:last-child {
  margin-bottom: 0px;
}

.ql-align-justify:after {
  content: "";
  display: inline-block;
  width: 100%;
}

.quill {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  height: inherit;
}

.ql-container {
  flex-grow: 1;
  overflow-y: auto;
  max-height: 100%;
}

.MuiToolbar-root {
  min-height: 48px;
}

.MuiAvatar-root {
  background-color: $azul-claro;
}

.jodit-wysiwyg {
  background-color: white;
}

/*.MuiInputLabel-root {
    text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
}*/

.item-menu-inicial {
  background-color: $azul-claro;
  color: black;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  div {
    div {
      margin: 10px;
      font-size: 24px;
      font-weight: bolder;
    }

    p {
      margin: 10px;
      font-size: 20px;
      color: dimgrey
    }
  }
}

.item-menu-inicial:hover {
  background-color: darken($azul-claro, 5%);
}

.MuiCallout-root {
  padding: 16px;
  margin: 16px 0px;
  border: 1px solid;
  border-radius: 10px;
}

.MuiCallout-info {
  color: rgb(0, 58, 117);
  background-color: rgba(240, 247, 255, 0.8);
  border-color: rgb(194, 224, 255);
}

.display-html {
  all: initial;
  
  dd {
    margin: 0;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
  }

  td,
  th {
    border: 0.5px gray solid;
    p {
      text-indent: 0mm;
      text-align: left;
    }
  }
  
  li {
    margin-left: 20mm;
    p {
      text-indent: 0mm;
    }
  }

  p {
    text-align: justify;
    text-indent: 20mm;
    font-family: Arial, sans-serif;
    //font-size: 12px;
  }
}