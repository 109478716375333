
.alinha-direita {
    text-align: right;
}

.alinha-centro {
    text-align: center;
}

.header {
    font-weight: bold;
    text-align: center;
}
